import { Inject, Injectable, InjectionToken } from '@angular/core';
import * as Sentry from '@sentry/angular';
import type { ErrorEvent, Options } from '@sentry/core';

export const SENTRY_DSN = new InjectionToken<string>('SENTRY_DSN');
export const SENTRY_ENVIRONMENT = new InjectionToken<string>('SENTRY_ENVIRONMENT');

@Injectable({
  providedIn: 'root'
})
export class SentryConfigService implements Options {
  readonly integrations = [];
  readonly debug = false;

  readonly transport = Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport);

  constructor(
    @Inject(SENTRY_DSN) public dsn: string,
    @Inject(SENTRY_ENVIRONMENT) public environment: string
  ) {
    this.dsn = dsn;
    this.environment = environment;
  }

  beforeSend = (event: ErrorEvent): ErrorEvent | null => {
    const extendedEvent = event;
    extendedEvent.tags = extendedEvent.tags ?? {};

    if (typeof navigator !== 'undefined') {
      extendedEvent.tags['connection.isOnline'] = navigator.onLine;
      extendedEvent.tags['connection.type'] = (navigator as any).connection?.effectiveType;
      extendedEvent.tags['connection.downlink'] = (navigator as any).connection?.downlink;
      extendedEvent.tags['connection.rtt'] = (navigator as any).connection?.rtt;
      extendedEvent.tags['connection.saveData'] = (navigator as any).connection?.saveData;
    }
    
    return extendedEvent;
  };
}
