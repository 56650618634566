import { Injectable, Injector, PLATFORM_ID } from '@angular/core';
import * as Sentry from '@sentry/angular';
import {
    SENTRY_DSN,
    SENTRY_ENVIRONMENT,
    SentryConfigService
} from "../sentry-config/sentry-config.service";
import { environment } from "../../../environments/environment";
import type { Client } from '@sentry/core';

@Injectable({
  providedIn: 'root'
})
export class SentryService {
  instance?: Client;
  private injector: Injector;

  constructor() {
    this.injector = Injector.create({
      providers: [
        { provide: PLATFORM_ID, useValue: 'browser' },
        { provide: SENTRY_DSN, useValue: 'https://26b4bf11291b42fa88719f787aa133d7@o130295.ingest.us.sentry.io/4507685590466560' },
        { provide: SENTRY_ENVIRONMENT, useValue: environment.name },
        {
          provide: SentryConfigService,
          useFactory: () => {
            return new SentryConfigService(
              this.injector.get(SENTRY_DSN),
              this.injector.get(SENTRY_ENVIRONMENT)
            );
          }
        }
      ]
    });
  }

  init() {
    const sentryConfigService = this.injector.get(SentryConfigService);
    this.instance = Sentry.init({ ...sentryConfigService });
  }
}
